import React from "react";

const Gallery = () => {
  return (
    <>
      <div className="dr-header">
        <div className="header-contant">
          <h1> Gallery</h1>
          <p>Doctor {" > "} Gallery </p>
        </div>
      </div>

      <div className="gallery">
        <div className="gallery-row">
          <div className="gallery-col">
            <img src="./images/g1.jpeg" alt="" />
          </div>
          <div className="gallery-col">
            <img src="./images/g2.jpeg" alt="" />
          </div>
          <div className="gallery-col">
            <img src="./images/g3.jpeg" alt="" />
          </div>
        </div>
        <div className="gallery-row">
          <div className="gallery-col">
            <img src="./images/g4.jpeg" alt="" />
          </div>
          <div className="gallery-col">
            <img src="./images/g5.jpeg" alt="" />
          </div>
          <div className="gallery-col">
            <img src="./images/g6.jpeg" alt="" />
          </div>
        </div>
        <div className="gallery-row">
          <div className="gallery-col">
            <img src="./images/g7.jpeg" alt="" />
          </div>
          <div className="gallery-col">
            <img src="./images/g8.jpeg" alt="" />
          </div>
          <div className="gallery-col">
            <img src="./images/g10.jpeg" alt="" />
          </div>
        </div>
        <div className="gallery-row">
          <div className="gallery-col">
            <img src="./images/g9.jpeg" alt="" />
          </div>
          <div className="gallery-col">
            <img src="./images/g11.jpeg" alt="" />
          </div>
          <div className="gallery-col">
            <img src="./images/g12.jpeg" alt="" />
          </div>
        </div>
        <div className="gallery-row img-fix">
          {/* <div className="gallery-col">
            <img src="./images/g13.jpeg" alt="" />
          </div> */}
          {/* <div className="gallery-col certificate-1">
            <img src="./img/maravi-certificate.jpeg" alt="" />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Gallery;

import React from "react";

const Heart = () => {
  return (
    <>
      <div className="dr-header">
        <div className="header-contant">
          <h1> Treatment</h1>
          <p>Doctor {" > "} Eye Glass Prescription Treatment </p>
        </div>
      </div>
      <div className="diabp">
        <h1>Eye Glass Prescription Treatment</h1>
        <div className="bp-row">
          <div className="bp-col">
            <p>
              A minus sign before the number indicates that you are nearsighted,
              while a plus sign means that you're farsighted. Lens power is
              measured in diopters. The higher the number, the stronger the
              prescription.
            </p>
            <p>
              An eyeglass prescription is an order written by an eyewear
              prescriber, such as an optometrist, that specifies the value of
              all parameters the prescriber has deemed necessary to construct
              and/or dispense corrective lenses appropriate for a patient. If an
              eye examination indicates that corrective lenses are appropriate,
              the prescriber generally provides the patient with an eyewear
              prescription at the conclusion of the exam.
            </p>
          </div>
          <div className="bp-col">
            <img src="./images/Eye_glass.jpeg" alt="" />
          </div>
        </div>
      </div>
      <div className="diabp">
        <h1>Right and Left Eye</h1>
        <div className="bp-row">
          <div className="bp-col">
            <p>
              In a typical prescription (like the one above), you’ll find these
              abbreviations on the left side:
            </p>
            <p>O.D. This is short for oculus dexter, and is your right eye</p>
            <p>O.S. This is short for oculus sinister, and is your left eye</p>
          </div>
          {/* <div className="bp-col">
            <img src="./img/heart.jpeg" alt="" />
          </div> */}
        </div>
      </div>
      <div className="diabp">
        <h1>Sphere (SPH)</h1>
        <div className="bp-row">
          <div className="bp-col">
            <p>
              The sphere column is often abbreviated as SPH. This is the lens
              power needed to fix your vision. A minus sign (₋) next to the
              number means nearsightedness (you see better up close and need
              distance correction) and a plus sign (₊) is farsightedness (you
              see better far away and need near correction).
            </p>
            {/* <p>
              An eyeglass prescription is an order written by an eyewear
              prescriber, such as an optometrist, that specifies the value of
              all parameters the prescriber has deemed necessary to construct
              and/or dispense corrective lenses appropriate for a patient. If an
              eye examination indicates that corrective lenses are appropriate,
              the prescriber generally provides the patient with an eyewear
              prescription at the conclusion of the exam.
            </p> */}
          </div>
          {/* <div className="bp-col">
            <img src="./img/heart.jpeg" alt="" />
          </div> */}
        </div>
      </div>
      <div className="diabp">
        <h1>Cylinder (CYL)</h1>
        <div className="bp-row">
          <div className="bp-col">
            <p>
              The cylinder number is how much astigmatism you have, if any. This
              is when part of the cornea has a different curve. Normally, an eye
              is shaped like a basketball—rotated any direction, the curve stays
              the same.
            </p>
          </div>
        </div>
      </div>
      <div className="diabp">
        <h1>Axis</h1>
        <div className="bp-row">
          <div className="bp-col">
            <p>
              This number tells you where the astigmatism is on the cornea. The
              axis—written in degrees between 1 and 180—indicates which way the
              astigmatism lines up.
            </p>
          </div>
        </div>
      </div>
      <div className="diabp">
        <h1>Add</h1>
        <div className="bp-row">
          <div className="bp-col">
            <p>
              There is where any additional lens powers are written. For
              example, some people over age 40 don’t want an extra pair of
              glasses for reading. They choose to wear bifocals instead. The
              lower half of the lens gives them their reading vision.
            </p>
          </div>
        </div>
      </div>
      <div className="diabp">
        <h1>Prism</h1>
        <div className="bp-row">
          <div className="bp-col">
            <p>
              There may be a field for prism on the right side (not pictured).
              This is a special type of correction built into the lens for some
              people with double vision (seeing two separate images of the same
              object).
            </p>
          </div>
        </div>
      </div>
      <div className="diabp">
        <h1>Type of Lenses </h1>
        <div className="bp-row">
          <div className="bp-col">
            <p>
              Single vision lenses—made for one, consistent focal power to
              correct a single vision condition. Progressive lenses—correct more
              than one vision condition, with the focal power “progressing” from
              top to bottom without a visible line separating them. Concave
              lenses—used to treat near-sightedness, or myopia.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Heart;

import React, { useState } from "react";
import { FaFacebook } from "react-icons/fa";
import { BsGoogle } from "react-icons/bs";

const Contact = () => {
  const [gmail, setGmail] = useState("");
  const notify = () => {
    //  if(gmail){
    //   toast.success("Succesfull  ",{
    //     position: "top-center"
    //   });
    //  }
  };
  return (
    <>
      <div className="dr-header">
        <div className="header-contant">
          <h1> Contact Us</h1>
          <p>Doctor {" > "} Contact Us </p>
        </div>
      </div>

      <div className="contact">
        <div className="contact-row">
          <div className="contact-col">
            <h2>Our Clinic Inforamtion</h2>
            <div className="contact-col-l con-bor">
              <i class="fa-solid fa-location-dot"></i>
              <div className="contact-cont">
                <h5>Location</h5>
                <p>
                  # Dr. Agrawal Clinic and Eye Care Center
                  <br />
                  Dr. Saket Agrawal C/o Anil Agencies, Below Ashoka Hotel, Bus
                  stand road, Mandla
                </p>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3683.4507945996843!2d80.37140409999999!3d22.5996354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398097263be584a5%3A0x281391399e2e4861!2sDr.%20Saket%20Agrawal%20Eye%20Care%20Center!5e0!3m2!1sen!2sin!4v1675668036380!5m2!1sen!2sin"
                  width="100%"
                  height="auto"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="contact-col-l con-bor">
              <i class="fa-solid fa-phone-volume"></i>
              <div>
                <h5>Call Us</h5>
                <p>
                  <a href="tel: 9479663683 ">9479663683</a> ,{" "}
                  
                </p>
              </div>
            </div>
            <div className="contact-col-l">
              <i class="fa-solid fa-envelope"></i>
              <div>
                <h5>Email</h5>
                <p>
                  <a href="mailto: saket_agrawal89@yahoo.co.in">
                  saket_agrawal89@yahoo.co.in
                  </a>{" "}
                </p>
              </div>
            </div>
            <div className="contact-col-l">
              {/* <i class="fa-solid fa-envelope"></i> */}
              <span>
                {" "}
                <FaFacebook />
              </span>
              <div>
                <h5>Facebook</h5>
                <p>
                  <a
                    href="https://www.facebook.com/dragrawaleyecare"
                    target="_blank"
                  >
                    https://www.facebook.com/dragrawaleyecare
                  </a>{" "}
                </p>
              </div>
            </div>
            <div className="contact-col-l">
              <span>
                <BsGoogle />
              </span>
              <div>
                <h5>Google</h5>
                <p>
                  <a href="https://g.co/kgs/J9i5AZ" target="_blank">
                    https://g.co/kgs/J9i5AZ
                  </a>{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="contact-col contact-col-r">
            <h1>Enquiry Now.</h1>

            <form action="#" method="POST">
              <div class="row">
                <div class="col">
                  <input
                    type="text"
                    name="UserName"
                    class="form-control"
                    placeholder="Your Name*"
                    aria-label="Your Name"
                    autoComplete="off"
                    required
                  />
                </div>
                <div class="col">
                  <input
                    type="number"
                    name="Number"
                    class="form-control"
                    placeholder="Your Phone"
                    aria-label="Your Phone"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <input
                    type="email"
                    name="Email"
                    class="form-control"
                    placeholder="Your Email*"
                    aria-label="Your Email"
                    autoComplete="off"
                    value={gmail}
                    onChange={(e) => setGmail(e.target.value)}
                    required
                  />
                </div>
                <div class="col">
                  <input
                    type="text"
                    name="Subject"
                    class="form-control"
                    placeholder="Subject"
                    aria-label="Subject"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
              <div class="form-floating">
                <textarea
                  name="Message"
                  class="form-control"
                  placeholder="Message"
                  id="floatingTextarea"
                ></textarea>
                <label for="floatingTextarea">Message</label>
              </div>
              <div className="send-msg">
                <button type="submit" onClick={() => notify()}>
                  SEND MESSAGE
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;

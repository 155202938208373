import React from "react";

const Resip = () => {
  return (
    <>
      <div className="dr-header">
        <div className="header-contant">
          <h1> Treatment</h1>
          <p>Doctor {" > "} Glaucoma Dianosis Treatment</p>
        </div>
      </div>
      <div className="diabp">
        <h1> Glaucoma Dianosis Using Advanced Keeler Digital Applanation</h1>
        <div className="bp-row">
          <div className="bp-col">
            <p>
              Glaucoma is usually picked up during a routine eye test, often
              before it causes any noticeable symptoms. Other tests are usually
              needed afterwards to diagnose and monitor the condition. It's
              important to have regular eye tests so problems such as glaucoma
              can be diagnosed and treated as early as possible.
            </p>
            <p>
              Eye doctors can check for glaucoma as part of a comprehensive
              dilated eye exam. The exam is simple and painless — your doctor
              will give you some eye drops to dilate (widen) your pupil and then
              check your eyes for glaucoma and other eye problems. The exam
              includes a visual field test to check your side vision.
            </p>
          </div>
          <div className="bp-col">
            <img src="./images/Glaucoma.jpeg" alt="" />
          </div>
        </div>
      </div>
      <div className="diabp">
        <h1> Keeler Digital Applanation</h1>
        <div className="bp-row">
          <div className="bp-col">
            <p>
              The Keeler-manufactured applanation tonometers are designed and
              built to our exacting standards to give you the accuracy and
              quality you expect from a Keeler device. All KAT tonometers are
              supplied with one reusable doubling prism, the calibration check
              bar, and either the Z-type (Zeiss) or the R-type post or the
              T-type aluminium guide plate for slit lamp use.
            </p>
            <p>
              The Keeler D-KAT delivers reliability and ease of use. With its
              LED display and internal electronics it allows for the fast
              accurate measurement of IOP (intraocular pressure). Available in
              R-type, T-type, and Z-type (Zeiss style) models. With less moving
              parts, the D-KAT is more reliable, accurate and recalibration
              takes just seconds.
            </p>
          </div>
          {/* <div className="bp-col">
            <img src="./img/respi.jpg" alt="" />
          </div> */}
        </div>
      </div>
      <div className="diabp">
        <h1>Types of Keelar Digital Applanation </h1>
        <div className="bp-row">
          <div className="bp-col">
            <b style={{color:"#243A74"}}>R-Type Applanation Tonometers</b>
            <p>
              R-type style applanation tonometer is available in traditional or
              digital formats. It is designed to be used with the H series slit
              lamp (tower illumination). The R-type tonometer is fixed allowing
              for it to easily swing into and out of position as needed without
              being removed.
            </p>
            <b style={{color:"#243A74"}}>T-Type Applanation Tonometers</b>
            <p>
              T-type style applanation tonometer is available in traditional or
              digital formats. It is designed to be used with the H series slit
              lamp (tower illumination). The T-type tonometer is a takeaway
              model allowing for the unit to removed when not in use.
            </p>
            <b style={{color:"#243A74"}}>z-Type Applanation Tonometers</b>
            <p>
              Z-type style applanation tonometer is only available in a digital
              format. It is designed to be used with the Z series slit lamp
              (lower illumination). The Z-type tonometer is fixed allowing for
              it to easily swing into and out of position as needed without
              being removed.
            </p>
          </div>
          {/* <div className="bp-col">
            <img src="./img/respi.jpg" alt="" />
          </div> */}
        </div>
      </div>
      <div className="diabp">
        <h1> Glaucoma Dianosis Using Advanced Keeler Digital Applanation</h1>
        <div className="bp-row">
          <div className="bp-col">
            <p>
              Glaucoma is usually picked up during a routine eye test, often
              before it causes any noticeable symptoms. Other tests are usually
              needed afterwards to diagnose and monitor the condition. It's
              important to have regular eye tests so problems such as glaucoma
              can be diagnosed and treated as early as possible.
            </p>
            <p>
              Eye doctors can check for glaucoma as part of a comprehensive
              dilated eye exam. The exam is simple and painless — your doctor
              will give you some eye drops to dilate (widen) your pupil and then
              check your eyes for glaucoma and other eye problems. The exam
              includes a visual field test to check your side vision.
            </p>
          </div>
          {/* <div className="bp-col">
            <img src="./img/respi.jpg" alt="" />
          </div> */}
        </div>
      </div>
      <div className="diabp">
        <h1 > What is Used to Diagnose Glaucoma </h1>
        <div className="bp-row">
          <div className="bp-col">
            <p>
              A gonioscopy can help to determine whether this area (the "angle")
              is open or closed (blocked), which can affect how fluid drains out
              of your eye. It will tell your optometrist what type of glaucoma
              you have.
            </p>
            <b style={{color:"#243A74"}}>What is Applanation Tonometry Used For</b>
            <p>
              This test measures fluid pressure in your eye. The test involves
              using a slit lamp equipped with forehead and chin supports and a
              tiny, flat-tipped cone that gently comes into contact with your
              cornea. The test measures the amount of force needed to
              temporarily flatten a part of your cornea.
            </p>
          </div>
          {/* <div className="bp-col">
            <img src="./img/respi.jpg" alt="" />
          </div> */}
        </div>
      </div>
      {/* <div className="diabp">
        <h1> Glaucoma Dianosis using advanced Keeler Digital Applanation</h1>
        <div className="bp-row">
          <div className="bp-col">
            <p>
              Glaucoma is usually picked up during a routine eye test, often
              before it causes any noticeable symptoms. Other tests are usually
              needed afterwards to diagnose and monitor the condition. It's
              important to have regular eye tests so problems such as glaucoma
              can be diagnosed and treated as early as possible.
            </p>
            <p>
              Eye doctors can check for glaucoma as part of a comprehensive
              dilated eye exam. The exam is simple and painless — your doctor
              will give you some eye drops to dilate (widen) your pupil and then
              check your eyes for glaucoma and other eye problems. The exam
              includes a visual field test to check your side vision.
            </p>
          </div>
          
        </div>
      </div> */}
    </>
  );
};

export default Resip;

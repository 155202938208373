import React from "react";

const Child = () => {
  return (
    <>
      <div className="dr-header">
        <div className="header-contant">
          <h1> Treatment</h1>
          <p>Doctor {" > "} Retina Checkup Treatment</p>
        </div>
      </div>

      <div className="diabp">
        <h1> Retina Checkup Treatment</h1>
        <div className="bp-row">
          <div className="bp-col">
            <p>
              Retinal diseases vary widely, but most of them cause visual
              symptoms. Retinal diseases can affect any part of your retina, a
              thin layer of tissue on the inside back wall of your eye.
            </p>
            <p>
              The retina contains millions of light-sensitive cells (rods and
              cones) and other nerve cells that receive and organize visual
              information. Your retina sends this information to your brain
              through your optic nerve, enabling you to see.
            </p>
            <p>
              Treatment is available for some retinal diseases. Depending on
              your condition, treatment goals may be to stop or slow the disease
              and preserve, improve or restore your vision. Untreated, some
              retinal diseases can cause severe vision loss or blindness.
            </p>
          </div>
          <div className="bp-col">
            <img src="./images/retina-check.jpeg" alt="" />
          </div>
        </div>

        <b style={{ color: "#243A74" }}>
          Common Retinal Diseases and conditions include:
        </b>
        <p>
          <b style={{ color: "#243A74" }}> Retinal Tear</b>- A Retinal tear
          occurs when the clear, gel-like substance in the center of your eye
          (vitreous) shrinks and tugs on the thin layer of tissue lining the
          back of your eye (retina) with enough traction to cause a break in the
          tissue. It's often accompanied by the sudden onset of symptoms such as
          floaters and flashing lights.
        </p>
        <p>
          <b style={{ color: "#243A74" }}>Retinal Detachment</b>- A Retinal
          detachment is defined by the presence of fluid under the retina. This
          usually occurs when fluid passes through a retinal tear, causing the
          retina to lift away from the underlying tissue layers.
        </p>
        <p>
          <b style={{ color: "#243A74" }}>Diabetic Retinopathy</b> - If you have
          diabetes, the tiny blood vessels (capillaries) in the back of your eye
          can deteriorate and leak fluid into and under the retina. This causes
          the retina to swell, which may blur or distort your vision. Or you may
          develop new, abnormal capillaries that break and bleed. This also
          worsens your vision.
        </p>
        <p>
          <b style={{ color: "#243A74" }}>Epiretinal Membrane</b> - Epiretinal
          membrane is a delicate tissue-like scar or membrane that looks like
          crinkled cellophane lying on top of the retina. This membrane pulls up
          on the retina, which distorts your vision. Objects may appear blurred
          or crooked.
        </p>
        <p>
          <b style={{ color: "#243A74" }}>Macular Hole</b> - A Macular Hole is a
          small defect in the center of the retina at the back of your eye
          (macula). The hole may develop from abnormal traction between the
          retina and the vitreous, or it may follow an injury to the eye.
        </p>
        <p>
          <b style={{ color: "#243A74" }}>Macular Degeneration</b> - In Macular
          Degeneration, the center of your retina begins to deteriorate. This
          causes symptoms such as blurred central vision or a blind spot in the
          center of the visual field. There are two types — wet macular
          degeneration and dry macular degeneration. Many people will first have
          the dry form, which can progress to the wet form in one or both eyes.
        </p>
        <p>
          <b style={{ color: "#243A74" }}>Retinitis Pigmentosa</b> - Retinitis
          Pigmentosa is an inherited degenerative disease. It slowly affects the
          retina and causes loss of night and side vision.
        </p>
        <b style={{ color: "#243A74" }}>
          Who should get regular retina checkups-
        </b>
        <p>
          Regular retina eye checkup is vital even if have never worn glasses in
          your life. Once you cross 50, your eyes won’t be the same even if you
          had a twenty-twenty vision all your life. The reason behind this is
          simple, just like your physical health reduces with age, your eye also
          starts to decline.
        </p>
        <b style={{ color: "#243A74" }}>
          40s: Have routine eye exams at least once every year.
        </b>
        <br />
        <br />
        <b style={{ color: "#243A74" }}>
          50s: Risks increase for retina disorder and glaucoma. Have routine eye
          exams once in 6 months.
        </b>
        <br />
        <br />
        <b style={{ color: "#243A74" }}>When should you get Retina Checkup- </b>
        <p>Vision Disruptions</p>
        <p>Difficulty Focusing</p>
        <p>Diabetic/High Blood pressure</p>
        <p>Night Blindness </p>
        <p>Blurry Vision</p>
      </div>
    </>
  );
};

export default Child;

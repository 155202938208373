import React from "react";
import { Link } from "react-router-dom";


const Aboutus = () => {
  return (
    <>
      <div className="dr-header">
        <div className="header-contant">
          <h1> About Us</h1>
          <p>Doctor {" > "} About Us </p>
        </div>
      </div>

      <div className="welcome">
        <h3>About Dr. Saket Agrawal </h3>
        <div className="wel-row">
          <div className="wel-col ">
            <img src="./images/doc1.jpeg" />
          </div>
          <div className="wel-col">
            <p>
              <b>Dr. Saket Agrawal is an eye specialist and surgeon,</b> He was gold
              medalist during his post graduation and has extensive work
              experience. Available facilities include complete eye check-up,
              glass prescription, glaucoma and retina check-up, Diabetic
              retinopathy screening, management of watering eyes, Dry eyes and
              other eye diseases, ophthalmic emergencies. Well equipped OT with
              facility for cataract and other eye surgeries
            </p>
            <p>
              Dr. Saket Agrawal is highly Qualified with 8 years of experience .
            </p>
            <p>
              He is well known all over<b> Mandla </b> He is Expertise in all
              <em>
                <b> eye specialist & surgeon </b>
              </em>
              Many patients come to
              <b>
                {" "}
                Dr. Saket Agrawal Eye Clinic And Surgeon, Mandla
              </b>{" "}
              to treat health-related issues from all cities in Madhya Pradesh.
            </p>
            <p>
              <b>
                {" "}
                Dr.Saket Agrawal Clinic and Eye Care Center Establishment Year - 2020
              </b>
            </p>

            <b>Qualification:</b>
            <p>
              MBBS, DOMS, FAGE (Gold Medallist)
              <br /> MBBS from Kasturba Medical College, Mangalore (Manipal
              University)
            </p>
            <p>
              <b>DOMS (Gold Medallist) Shyam Shah Medical College, Rewa </b>
              <br />
              <b>Ex surgeon Sadguru Netra Chikitsalaya, Chitrakoot</b>
            </p>
          </div>
        </div>
      </div>

      <div className="doctor-about">
        <p>
          <b> Dr. Saket Agrawal </b>(Eye Clinic) in Mandla City, Mandla.
        </p>
        <p>
          If you are looking for a good Eye surgeon for you, then we suggest you
          visit Dr. Saket Agrawal (Eye Clinic) in Mandla City, Mandla. Dr.Saket
          Agrawal (Eye Clinic) has been a part of the healthcare system since
          2020. Dr. Saket Agrawal (Eye Clinic) has a great reputation in the
          industry for treating Eye and keeping them in the best of health.
        </p>
        <p>
          <b style={{ color: "#1a646f" }}>About Clinic - </b> Dr. Saket Agrawal
          is an eye specialist and surgeon , Mandla
          <b> has a well-equipped clinic with all the modern equipment</b>. The
          clinic has separate waiting and consultation areas which allow enough
          space for patients to wait conveniently at the clinic. Being a Dr.
          Saket agrawal is an eye specialist and surgeon, the doctor offers a
          number of medical services.
        </p>
      </div>

      <div className="certificate">
        <h1>
          Our Achievements <span style={{ color: "#243A74" }}></span>
        </h1>
        <div className="c-row">
          <div className="c-col">
            <img src="./images/a1.jpeg" alt="" />
          </div>
          <div className="c-col">
            <img src="./images/a2.jpeg" alt="" />
          </div>
          <div className="c-col">
            <img src="./images/a3.jpeg" alt="" />
          </div>
        </div>

       <div className="doc">
        <a href="./images/pdf.pdf" target="_blank">Read More About Achievements</a>
       </div>

        {/* <div className="c-row">
          <div className="c-col">
            <img src="./images/p1.jpeg" alt="" />
          </div>
          <div className="c-col">
            <img src="./images/p2.jpeg" alt="" />
          </div>
          <div className="c-col">
            <img src="./images/p3.jpeg" alt="" />
          </div>
        </div> */}
        {/* <div className="c-row">
          <div className="c-col">
            <img src="./images/p4.jpeg" alt="" />
          </div>
          <div className="c-col">
            <img src="./img/c8.jpeg" alt="" />
          </div>
          <div className="c-col">
            <img src="./img/c9.jpeg" alt="" />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Aboutus;

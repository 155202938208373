import React from "react";

const Tyhro = () => {
  return (
    <>
      <div className="dr-header">
        <div className="header-contant">
          <h1> Treatment</h1>
          <p>Doctor {" > "}Cornea and Dry Eye Treatment </p>
        </div>
      </div>
      <div className="diabp">
        <h1>Cornea and Dry Eye Treatment </h1>
        <div className="bp-row">
          <div className="bp-col">
            <p>
              The transparent part of the eye that covers the iris and the pupil
              and allows light to enter the inside. The cornea is a transparent
              avascular tissue that acts as a structural barrier and protects
              the eye against infections. [1] Along with the tear film, it
              provides proper anterior refractive surface for the eye. Cornea
              contributes to two-third of the refractive power of the eye.
            </p>
            <p>
              Injuries to the cornea can occur anytime something abnormal makes
              contact with the cornea. Conditions like dry eye syndrome can also
              increase the risk for a corneal injury. Foreign bodies or trauma
              of the cornea can result in abrasions (scratches), keratitis
              (inflammation) or corneal edema (swelling).
            </p>
          </div>
          <div className="bp-col">
            <img src="./images/dryeye.jpeg" alt="" />
          </div>
        </div>
      </div>
      <div className="diabp">
        <h1>DRY EYES</h1>
        <div className="bp-row">
          <div className="bp-col">
            <p>
              For most people with occasional or mild dry eye symptoms, it's
              enough to regularly use nonprescription eye drops, also called
              artificial tears. If your symptoms are persistent and more
              serious, you have other options. What you do depends on what's
              causing your dry eyes.
            </p>
            <p>
              Some treatments focus on reversing or managing a condition or
              factor that's causing your dry eyes. Other treatments can improve
              your tear quality or stop your tears from quickly draining away
              from your eyes.
            </p>
          </div>
          {/* <div className="bp-col">
            <img src="./img/thro.jpg" alt="" />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Tyhro;

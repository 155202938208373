import React from "react";

const Diabbp = () => {
  return (
    <>
      <div className="dr-header">
        <div className="header-contant">
          <h1> Treatment</h1>
          <p>Doctor {" > "} Headache and Migrane Treatment </p>
        </div>
      </div>

      <div className="diabp">
        <h1>Headache and Migrane Treatment</h1>
        <div className="bp-row">
          <div className="bp-col">
            <p>
              A Headache is a pain or ache in your head, especially one that
              lasts longer than a few minutes.
            </p>
            <p>
              A headache may appear as a sharp pain, a throbbing sensation or a
              dull ache. Headaches can develop gradually or suddenly, and may
              last from less than an hour to several days.
            </p>
            <p>
              A migraine is a headache that can cause severe throbbing pain or a
              pulsing sensation, usually on one side of the head. It's often
              accompanied by nausea, vomiting, and extreme sensitivity to light
              and sound.
            </p>
            <p>
              A migraine is a headache that can cause severe throbbing pain or a
              pulsing sensation, usually on one side of the head. It's often
              accompanied by nausea, vomiting, and extreme sensitivity to light
              and sound.
            </p>
            <p>
              Migraines can be treated with two types of drugs: abortive and
              preventive. Abortive: The goal of abortive treatment is to stop a
              migraine once it starts. Abortive medications stop a migraine when
              you feel one coming or once it has begun.
            </p>
          </div>
          <div className="bp-col">
            {/* <img src="./img/bp.jpg" alt="" /> */}
            <img src="./img/headache.jpg" alt="" />
          </div>
        </div>
      </div>
      <div className="diabp">
        <h1>Symptoms of Headache & Migrane -  </h1>
        <div className="bp-row">
          <div className="bp-col">
            <p>
            Slow onset of the headache.
            </p>
            <p>
            Head usually hurts on both sides.
            </p>
            <p>
            Pain is dull or feels like a band or vice around the head.
            </p>
            <p>
            Sensitivity to light, sound, and sometimes smell and touch.
            </p>
            <p>
            Nausea and vomiting.
            </p>
          </div>
          {/* <div className="bp-col">
            <img src="./img/bp.jpg" alt="" />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Diabbp;

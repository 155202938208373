import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <div
        id="carouselExampleCaptions"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="./images/Eye4.png" class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="./images/slide1.png" class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="./images/Eye3.png" class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="./images/slide2.png" class="d-block w-100" alt="..." />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>

      <div className="welcome">
        <h3>About Dr.Saket Agrawal</h3>
        <div className="wel-row">
          <div className="wel-col ">
            <img src="./images/doc1.jpeg" />
          </div>
          <div className="wel-col">
            <p>
              <b>Dr. Saket Agrawal is an eye specialist and surgeon. </b>He was
              gold medalist during his post graduation and has extensive work
              experience. Available facilities include complete eye check-up,
              glass prescription, glaucoma and retina check-up, Diabetic
              retinopathy screening, management of watering eyes, Dry eyes and
              other eye diseases, ophthalmic emergencies. Well equipped OT with
              facility for cataract and other eye surgeries
            </p>
            <p>
              Dr. Saket Agrawal is highly Qualified with 8 years of experience .
            </p>
            <p>
              He is well known all over<b> Mandla. </b> He is Expertise in all
              <em>
                <b> eye specialist and surgeon.</b>
              </em>
              Many patients come to
              <b>Dr. Saket Agrawal Eye Clinic And Surgeon, Mandla</b>
              to treat health-related issues from all cities in Madhya Pradesh.
            </p>
            <p>
              <b>
                Dr. Saket Agrawal Eye Clinic And Surgeon ,Mandla Establishment
                Year - 2020
              </b>
            </p>
            <p>
              Dr. Saket Agrawal is <b> Eye Specialist & Surgeon </b>
            </p>

            <b>Qualification:</b>
            <p>
              MBBS, DOMS, FAGE (Gold Medallist)
              <br /> MBBS from Kasturba Medical College, Mangalore (Manipal
              University)
            </p>
            <p>
              <b>DOMS (Gold Medallist) Shyam Shah Medical College, Rewa </b>
              <br />
              <b>Ex surgeon Sadguru Netra Chikitsalaya, Chitrakoot</b>
            </p>
            <Link to="/aboutus">
              <button>Read More</button>
            </Link>
          </div>
        </div>
      </div>

      <div className="speciality">
        <h2>SURGERIES</h2>
        <div className="spec-row spec-row-1">
          <div className="spec-col">
            {/* <div>
              <img src="./img/h1.png" alt="" />
            </div> */}

            <div>
              <h3>Cataract Surgery </h3>
              <p>
                Cataract surgery involves removing the clouded lens and
                replacing it with a clear artificial lens. The artificial lens,
                called an intraocular lens, is positioned in the same place as
                your natural lens.
              </p>
            </div>
          </div>
          <div className="spec-col">
            {/* <div>
              <img src="./img/h2.png" alt="" />
            </div> */}
            <div>
              <h3>Pterygium Surgery </h3>
              <p>
                Pterygium surgery involves removal of the abnormal tissue from
                the sclera and cornea of the eye. Today's techniques offer a
                significantly higher success rate than conventional surgery.
              </p>
            </div>
          </div>
        </div>
        <div className="spec-row">
          <div className="spec-col">
            {/* <div>
              <img src="./img/h3.png" alt="" />
            </div> */}
            <div>
              <h3>DCR Surgery</h3>
              <p>
                Dacryocystorhinostomy (DCR) is a surgery that creates a new path
                for tears to drain between your eyes and your nose.
              </p>
            </div>
          </div>
          <div className="spec-col">
            <div>
              <h3>Lid Mass Excision</h3>
              <p>
                This surgery uses heat to ablate cancerous tumor tissue while
                sealing off blood vessels. Following this, the dead tissue is
                scraped away.
              </p>
            </div>
          </div>
        </div>
        <br />
        {/* <div className="spec-row">
          <div className="spec-col">
            <div>
              <h3>Glue with BCL </h3>
              <p>
                Cyanoacrylate Tissue Adhesive (TA) with Bandage Contact Lens
                (BCL) has become a cornerstone in treating severe corneal
                thinning and perforations ≤2.5 mm in size.
              </p>
            </div>
          </div>
          <div className="spec-col">
            <div>
              <h3>Sutureless</h3>
              <p>
                The sutureless technique did not reduce the rate of restenosis,
                progression of the disease to previously uninvolved pulmonary
                veins, or mortality rate.
              </p>
            </div>
          </div>
        </div> */}
        <div className="extra">
          <h3>Glue with BCL </h3>
          <p>
            Cyanoacrylate Tissue Adhesive (TA) with Bandage Contact Lens (BCL)
            has become a cornerstone in treating severe corneal thinning and
            perforations ≤2.5 mm in size.
          </p>
        </div>
        <div className="imp">
          <span>
            <h2>Cataract Surgery and Lens Transplant Facility Available</h2>
          </span>
        </div>
      </div>

      <section className="our-team">
        <h1>
          Eye Checkup and Surgeries
          <span style={{ color: "#1d4776" }}> With Latest Machines</span>
        </h1>
        <div className="team-row">
          <div className="team-col">
            <img src="images/machine1.jpeg" alt="" />
            <h6>Slit Lamp With Applanation Tonometer </h6>

            {/* <p>(B.H.M.S, M.D)</p> */}
          </div>
          <div className="team-col">
            <img src="images/machine2.jpeg" alt="" />
            <h6> Fully Computerised Glass Prescription</h6>

            {/* <p>B.H.M.S</p> */}
          </div>
          <div className="team-col">
            <img src="images/machine3.jpeg" alt="" />
            <h6>Labomed (USA) Operating Microscope</h6>

            {/* <p>B.H.M.S</p> */}
          </div>
          <div className="team-col">
            <img src="images/machine5.jpeg" alt="" />
            <h6>Modular OT</h6>

            {/* <p>B.H.M.S</p> */}
          </div>
        </div>
      </section>

      <div className="client">
        <img src="" alt="" />
        <div className="client-row">
          <h3>Our Testimonials</h3>
          <h1>What our Patients Say</h1>
        </div>
      </div>

      <div className="benefit">
        {/* <div className="benefit-row">
          <div className="benefit-col">
            <i class="fa-solid fa-user-doctor"></i>
            <h4>Effective and Fast Acting</h4>
            <p>
              Homeopathic medicine is fast acting, restoring optimal health.
              Homeopathic prescribing is effective in both acute and chronic
              conditions
            </p>
          </div>
          <div className="benefit-col">
            <i class="fa-solid fa-face-smile"></i>
            <h4>Gives Lasting Relief</h4>
            <p>
              Homeopathic treatments addresses disease at the root level, hence
              enhancing resistance to disease.
            </p>
          </div>
          <div className="benefit-col">
            <i class="fa-solid fa-microscope"></i>
            <h4>Free From Side Effects</h4>
            <p>
              Instead of suppressing symptoms, homeopathy gently works with the
              body to stimulate balance or healing.
            </p>
          </div>
        </div> */}
        <div className="benefit-row ">
          <div className="benefit-col">
            <i class="fa-solid fa-face-smile"></i>
            <h4>Shalu kushwaha</h4>
            <p>
              Doctor is highly experienced . Dr. Saket is very honest with his
              profession and I am very happy with his treatment🙂.
            </p>
          </div>
          <div className="benefit-col">
            <i class="fa-solid fa-face-smile"></i>
            <h4>Amit Tahilani</h4>
            <p>
              Best Opthalmologist in Mandla & Bamhni , Thank u so much dr. Saket
              Agarwal sir for the best treatment.
            </p>
          </div>
          <div className="benefit-col">
            <i class="fa-solid fa-face-smile"></i>
            <h4>Vikas Agrawal</h4>
            <p>
              Best Eye Clinic in Mandla , It was nice experience of visiting
              here and had a perfect solution of any eye diseases.
            </p>
          </div>
          <div className="benefit-col">
            <i class="fa-solid fa-face-smile"></i>
            <h4>Rajesh Jain</h4>
            <p>
              Wonderful office, patient oriented, caring treatment, no matter
              your age. Dr. Agrawal is honest and resourceful.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
